export default class Meigaranews2Service {
  getAll(params, meigaraCode) {
      const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');

      let url = null;
      if (meigaraCode) {
        url = 'https://api.tradersr.com/api/meigaranews2/' + meigaraCode + '?'
      } else {
        url = 'https://api.tradersr.com/api/meigaranews2?'
      }

      return fetch(url + queryParams).then(res => res.json())
  }
}